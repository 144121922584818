
/*this is the entire drawer */
.sideDrawer { 
  height: 100vh;
  background: white;
  position: fixed;
  width: calc(30% + 300px);
  z-index: 200;
  right: 0;
  box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
    
 }

 .sideDrawerMobile { 
  height: 100vh;
  background: white;
  position: fixed;
  width: 100%;
  z-index: 800;
  right: 0;
  box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  
}

 .sideDrawerOpen {
    transform: translateX(0);
 }

 /*this is the entire drawer minus broder margins */
 .drawerContainer {
   width: 85%;
   margin: auto;
   margin-top: 10px;
   margin-bottom: 100px;
  /*height: calc(100% - 10px - 10px)*/
  height: 100vh;
 }

 /*this is everything below the X and is the FORM container */
 .drawerContainer2 {
  display:flex;
  flex-direction: column;
  /*height: calc(100% - 60px);*/
  height: 95dvh;
  /*overflow: auto;*/
  margin-bottom: 60px;
  }

  /*this is the body portion that scrolls if long */
 .scrollControl {
  padding-bottom: 30px;
  height: 95dvh;
  overflow: auto;
}

  /*this is the submit button outside of the scroll */
 .drawerDoneButtonContainer {
  position: fixed;
  width: 85%;
  bottom: 0;
  z-index: 5;
  background-color: white;
  height: 60px;
}

 .close {
  display: block;
  margin-left: auto;
 }

 .close:hover {
  cursor: pointer;
 }
