.secret {
    
    height:20px;
    width:100px;
    position: absolute;
    top: 64px;
    left: 50%;
}

.textfield {
    width:400px;
    max-width:100%;
}

.margin {
    margin: 20px 0px;
}

.margin2 {
    margin: 10px 0px;
}

.length {
    width: 50%;
    display:block;
    margin: 20px auto;
}