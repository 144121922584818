.properties {
    margin: 30px;
}

.yearSelectorStats {
    display:flex;
    margin-top: -10px;
    width:100%;
    max-width: 400px;
    }

.windowSelector {
    margin-right: 10px;
    width:50%;
}

.checkboxWrap {
    display:flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    text-align: left;
}

.checkboxLine {
    display:flex;
    align-items: center;
}

.checkboxLine p {
    margin-left: 5px;
}

@media only screen and (max-width: 799px) {
    .properties {
        margin:5px;
    }
    .propertyNav {
        margin: 15px 5px 0px 5px;
    }
}

.propertyNav {
    display: flex;
    max-width: 100%;
    overflow: auto;
    border-radius: 10px;

}

/*in case we want to fix the property nav
.fixMenu {
    position: fixed;
}*/