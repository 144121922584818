.nextStepsRow {
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

.imgP {
    display:flex;
    padding-top: 15px;
}

.align {
    text-align: left;
    flex-basis: 385px;
    margin: 20px 35px;
    flex-grow: 1;
}

.imgP p {
    padding-left: 25px;
}

@media only screen and (max-width: 799px) {
    .align {
        margin: 20px 5%;

    }
    
}