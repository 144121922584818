.amount {
    display:flex;
    align-items: center;
    justify-content: center;
}

.amountContainer {
    display:flex;
    flex-direction: column;
    align-items: center;
}

.accountLine {
    display:flex;
}

.accountLine p {
    margin: -10px 5px 5px 5px;
}

.splitter {
    display: flex;
    justify-content: right;
    align-items: center;
    margin: 20px 0px;
}

.splitter img {
    margin-left: 20px;
}

.splitter:hover{
    cursor: pointer;
}

.ai {
    display:flex;
    align-items: center;
    margin-bottom: 75px;
}

.aiImageEditTrans {
    width:17.5px;
    padding-right: 5px; 
}

