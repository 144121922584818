.uploadBox {
    width: 98%;
    border: 2px dashed var(--positive);
    border-radius: 10px;
    margin: 30px auto;
    display: flex;
    justify-content: center;
}

.uploadBox p {
    text-align: center;
    width: 100%;
    padding: 50px 0;
    background-color: white;
}

.limarg {
    margin-left: 30px;
}

.emailClick {
    text-decoration: underline;
    color:var(--positive)
}

.emailClick:hover {
    cursor: pointer;
}