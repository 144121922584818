.logoutRow {
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
    width:100%;
}

.logoutGroup {
    display:flex;
    align-items: center;
    margin-bottom: 20px;
}

.group2 {
    padding-right: 10px;
}

.group2 :hover {
    cursor: pointer;
}

.logoutGroup img {
    height: 40px
}