.topLevel {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height: 95vh;
}

.title {
    display: flex; 
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.titleSpacing {
    margin: 5px;
}


.plaidLogo {
    max-height: 70px;
}

.logoSection {
    margin: 30px 20px;
}

.newSection {
    margin: 30px 0px 50px 0px;
}

.logos {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo1 {
    max-width: 20%;
    height:100%
}