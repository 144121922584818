.totalHeader {
    display:flex;
    margin-top: 50px;
    margin-bottom: 10px;

}

.headerLabelTrans {
    margin-right: 5px;
}

.transRow {
    display:flex;
    justify-content: space-between;
    margin: 15px 0px;

}

.transDivider {
    border-top: 1px dassolidhed var(--lineGray);
}

.dateWid {
width:20%;
text-align: left;
}

.vendorWid {
width:40%;
text-align: left;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
}

.amountWid{
width:30%;
text-align: right;
}
