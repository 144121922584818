
.introMargin {
    margin: 10px 0px;
}

.secondaryTextMargin {
    margin: 5px 0px;
}


.addAnotherButton {
 margin: 25px 0px;
}

.escrowInfoContainer {
    padding: 15px;
    background-color: var(--principleLight);
    border-radius: 7.5px;
    border: 1px solid var(--principle);
    margin: 15px 0px;
}

.paymentMethodMargin {
    margin-bottom: 10px;
}