.statsContainer {
    position: relative;
    width:100%;
}

.section {
    padding: 50px 15px 150px 15px;
}

.backgroundBlue {
    background-color: var(--backgroundLightGray);
}

.calculatingData {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px; /* Adjust top position as needed */
    left: 50%;
    transform: translateX(-50%); /* Move back by half of its own width */
  
    background-color: var(--secondaryGreen);
    border: 1px solid var(--positive);
    border-radius: 7px;
    padding: 10px;
    min-width: 400px
    
}

.sectionTitle {
    padding: 0px 12.5%;
    line-height: 150%;
}

.charts {
    display:flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.chartCol {
    overflow: auto;
    min-width: 40%;
    text-align: left;
    padding: 30px;
    flex-basis: 475px;
    flex-grow: 1;
}

.chartColHalf {
    overflow: auto;
    min-width: 40%;
    text-align: left;
    padding: 30px;
    flex-basis: 575px;
    flex-grow: 1;
}

.chartColBig {
    overflow: auto;
    min-width: 60%;
    text-align: left;
    padding: 40px;
    flex-basis: 750px;
    flex-grow: 1;
}

.chartColSmall {
    display:flex;
    justify-content: space-between;
    overflow: auto;
    min-width: 20%;
    text-align: left;
    flex-basis: 350px;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 40px 0px;
}

.dataTable {
    padding: 0px 40px 20px 40px;
    flex-basis: 355px;
    flex-grow: 1;
}

.legendFlex {
    display:flex;
    width: calc(100%-60px);
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    flex-wrap: wrap;
}

.legendWrap {
    flex-basis: 190px;
    flex-grow: 1;
    width: 30%;
    padding-bottom: 10px;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.legendFlex2 {
    display:flex;
    align-items: center;
}



.barChartLegend {
    width: 10px;
    height:10px;
    margin-right: 8px;
}

.barChartLegendIncome {
    background-color: var(--positive);
}

.barChartLegendExpense {
    background-color: var(--negative);
}

.lineChartLegend {
    width: 4px;
    border-top: 1px solid var(--cashFlow);
}

.lineChartLegendDot {
    width: 5px;
    height:5px;
    background-color: none;
    border: 1px solid var(--cashFlow);
    border-radius: 8px;
}

.lineChartLegendMargin {
    margin-right: 5px;
}


.averageLineLegend {
    width: 13px;
    margin-right: 5px;
}

.averageLineLegendIncome {
    border-top: 1px dashed var(--positive);
}

.averageLineLegendExpense {
    border-top: 1px dashed var(--negative);
}

.averageLineLegendCashFlow {
    border-top: 1px dashed var(--cashFlow);
}

.monthlyAverageLegend {
    width: 10px;
    height:10px;
    border-radius: 8px;
    margin-right: 8px;
}

.monthlyAverageLegendIncome {
    background-color: var(--positive);;
}

.monthlyAverageLegendExpense {
    background-color: var(--negative);;
}

.monthlyAverageLegendCashFlow {
    background-color: var(--cashFlow);;
}








.tvgTable {
   min-width: 300px;
}

.metricsTable{
    margin-top: 20px;
    padding: 30px;
    border: 1px solid var(--lineGray);
    border-radius: 20px;
    box-shadow: 5px 5px 15px var(--lineGray);
}

.chart {
    margin-top: 20px;
}

.metricsRow {
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;
}

.line {
    border: 1px solid var(--lineGray);
    margin: 5px 0px;
}

.tip {
    display:flex;
    justify-content: center;
}




.roeCompImg {
width: 100%;
}

.roeTable {
    min-width: 435px;
    display: flex;
    justify-content: space-between;
    text-align: right;
    align-items: center;
 }

 .specialRoeColumn {
    text-align: left;
 }

.colLabel {
    margin-bottom: 7px;
}


 .roeColumn p {
     padding: 5px;
 }

 .bottomBorder {
     border-bottom: 1px solid var(--lineGray);
 }

 .middleColumn {
     padding-right: 5px;
 }


 .tooltip {
    display:flex;
    align-items: center;
 }

 .tooltip p {
    margin-right: 5px;
 }

 .tooltip img:hover {
    cursor: pointer;
 }

.mobileTable {
    border-right: 1px solid var(--lineGray);
    border-top: 1px solid var(--lineGray);
    border-bottom: 1px solid var(--lineGray);
    border-radius: 5px;
    margin-top: 10px;
}

.cashBorder {
    border-left: 3px solid var(--cashFlow);
}

.incomeBorder {
    border-left: 3px solid var(--positive);
}

.expenseBorder {
    border-left: 3px solid var(--negative);
}

.appreciationBorder {
    border-left: 3px solid var(--appreciation);
}

.principleBorder {
    border-left: 3px solid var(--principle);
}

.tvgBorder {
    border-left: 3px solid var(--positive);
}

.tableMarg {
    margin-top: 20px;
}

.tableLabel {
    padding: 15px 10px 7.5px 10px;
}

.tableValues {
    padding: 7.5px 10px 15px 10px;

}

.singleRowPadding {
    padding: 15px 10px 15px 10px;
}

 .mobileTableFlex {
    display:flex;
    justify-content: space-between;
 }

 .mobileTableFlex2 {
    display:flex;
    justify-content: end;
 }

 .spacebar {
    margin-right: 2px;
 }

 @media only screen and (max-width: 799px) {
    .section {
        padding:20px 0px 50px 0px
    }
    .sectionTitle {
        padding: 0px 5%;
        line-height: 150%;
    }
    .charts {
        padding: 0px 5%;
    }
    .chartCol {
        padding:35px 0px; 
    }
    .chartColBig {
        padding: 40px 0px; 
    }
    .chartColSmall {
        padding: 40px 0px; 
    }
    .chartColHalf {
        padding: 40px 0px;
    }
    .dataTable {
        padding: 0px 0px 20px 0px;

    }
    .legendFlex {
        padding: 0px 0px;
    }
    .calculatingData {    
        top: 3px; /* Adjust top position as needed */
        padding: 6px;
        min-width: 300px    
    }
    .metricsTable{
        padding: 20px;
    }
    
}