.totalTaxed {
    display:flex;
    justify-content: space-between;  
    align-items: center;  
    margin:20px 40px 20px 40px;
}

.totalTaxLine {
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.prewrap {
    margin-left: 5px;
}

.totalTaxed p {
    margin-bottom: 5px;
}

.totalTaxesText {
display:flex;
flex-direction: column;
text-align: start;
}

.incExpCols {
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.incExpCol {
    display:flex;
    justify-content: start;
    margin: 20px 40px 20px 40px;
    overflow: auto;
    min-width: 40%;
    text-align: left;
    flex-basis: 475px;
    flex-grow: 1;
    flex-direction: column;
}

.colLabel {
    display:flex;
    border-bottom: 1px solid var(--lineGray);
    padding-bottom: 15px;
    align-items: center;
    min-width: 100%;
}

.colLabel p {
    margin-left: 5px;
}

.colTotals {
    padding-top: 15px;
    padding-bottom: 15px;
}

.totalsRow {
    display: flex;
    justify-content: space-between;
    align-items:center;
}

.interestActualsRow {
    display:flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
}

.interestActualsRowHover {
    display:flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--lightRed);
    border-radius: 5px;
}

.addingMargin {
    margin-bottom: 10px;
}

.disclaimer {
    display: flex;
    justify-content: flex-end;
}

.colRowItem {
    padding-top: 15px;
}

.rowItemContainer {
    background-color: var(--backgroundLightGray);
    border-radius: 5px;
    border: 1px solid var(--lineGray);
    margin-bottom: 5px;
}

.rowItemContainerMargin {
    padding: 15px;
}

.grow {
    width:100%;
}

.interestGrow {
    width: calc(100% - 49px);
}

.hideImage {
    visibility: hidden;
}

.totalsRow img {
    padding-left: 15px;
}

.rowLeftPadding {
    padding-left: 10px;
}

.subRow p {
    margin-top: 2.5px;
    margin-bottom: 2.5px;
    margin-left: 10px;
}

.tadMorePadding {
    margin-top: 10px;
}

.paddingAndBorder {
    padding-bottom: 15px;
    border-bottom: 1px solid var(--lineGray);
}

.addNewRow {
    display: flex;
    justify-content: flex-end;
    align-items:center;
    margin-top: 10px;
}

.addNewRow p {
    margin-right: 10px;
}

.trashDiv {
    padding-left: 15px;
}

.trashDiv img {
    padding:2px
}

.rowTextLabel {
    max-width:70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rowValueLabel {
    min-width: fit-content;
    width:30%;
    text-align: end;}

.interestActualsRow img, .interestActualsRowHover img{
    width: 20px;
    height:auto;
}




@media only screen and (max-width: 800px) {
    .totalTaxed, .incExpCol {
        margin: 20px 15px 20px 15px;
       
    }
    .totalTaxed {
        text-align: center;
    }
    .totalTaxLine {
        justify-content: center;
    }
    .totalTaxesText {
        text-align: center;
    }
  }