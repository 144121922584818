.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.sideNavDrawer .container li {
    line-height: 0;
    list-style-type: none
}

.container2 {
    display:flex;
    justify-content: space-between;
}

.logo {
    background-color: var(--positive);
    height: 84px;
    display: flex;
    justify-content: space-between;
}

.logo img {
    height: 100%;
    width:66px;
}

.tab:hover {
    cursor: pointer;
}

.item {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item:hover {
    cursor: pointer;
}

.activeTab {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondaryGreen);
}

.list2 {
    padding-bottom: 10px;
}

.addTab:hover {
    cursor: pointer;
}

.hideBullet {
    list-style: none;
    width: 20%;
}