.addMenu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 25px auto;
}

.menuGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%
}

.menuGroup:hover {
    cursor: pointer;
}

.menuGroup img {
    margin: 0 auto;
}

.menuGroup p {
    margin-top: 10px;
}

.secureDiv {
    display:flex;
    color: var(--waybozGray);
  font-size: 14px;
  font-weight: 400;
  text-align: center;
 justify-content: center;
}

.secureDiv img {
    margin-left: 0;
}

.secureDiv p {
    padding-left: 5px;
}

.or {
    display:flex;
    width:100%;
    align-items:center;
}

.orLine {
    width:100%;
    margin: 0px 10px;
}

.orLine hr {
    border-top: 1px dashed var(--lineGray);

}

.spacing {
    margin:30px 0px;
}

.manualEnter:hover {
    cursor: pointer;
}

.amountRow {
    display:flex;
    justify-content: center;
    align-items: center;
}

.amountRow p {
    font-size: 84px;
}

.amountRowIncome {
    color: var(--positive)
}

.amountRowExpense {
    color: var(--negative)
}