.top {
    display:flex;
}

.col {
    display: flex;
    justify-content: center;
    width: 50%;
    height:100vh;
}

.colThin {
    display: flex;
    justify-content: center;
    width: 100%;
    height:100vh;
}

.firstcol {
background-color: var(--backgroundLightGray);
}

.contentDiv {
    height:100vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.widthControl {
    min-width:40%;
}

.space {
    margin: 15px 0px;
}

.exSpace {
    margin-top: 125px;
}

.border {
    border-bottom: 1px solid var(--lineGray);
}

.fields {
    width:100%;
}

.termsRow {
    display:flex;
    align-items: center;
    margin: 10px 0px;
}

.leftMargin {
    margin-left: 5px;
}

.anchor {
    color: var(--waybozGray);
    font-size: 16px;
    font-weight: 500;
  }