.propertyTab {
    border: 1px solid var(--lineGray);
    min-width: 200px;
    max-width: 200px;
    height: 135px;
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 16px;
    overflow:hidden;
    box-shadow: 5px 5px 15px var(--lineGray);
    
}

.selected {
    background-color: var(--waybozBlue);
}

.notIncluded {
    opacity: .5;
}

.unselected {
    background-color: #FFFFFF;
}

.propertyTab:hover {
    cursor: pointer;
}

.propertyTabImgContainer{
    display:flex;
    justify-content: center;
    height: 60%
}

.propertyTabImgContainer img {
    width: 100%;
}

.propertyTabInfo {
    padding: 10px;
}

.unassigned {
    background-color: var(--negative);
    height: 60%;
    display:flex;
    justify-content: center;
}



.unassigned img, .personal img {
   margin: 5%
}

.personal, .aiTab {
    background-color: white;
    height: 60%;
    display:flex;
    justify-content: center;
    border-bottom: 1px solid var(--lineGray);
}

.aiTab img  {
    padding: 3%;
}

