.trialFlex {
    display:flex;
    justify-content: space-between;
    margin-top: 50px;
    flex-wrap: wrap;
}

.trialContainer {
    border: 2px solid var(--positive);
    margin: 10px;
    padding: 16px;
    border-radius: 12px;
    width: 43%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 25px;
    flex-basis: 225px;
    flex-grow: 1;
}

.shortContainer {
   background-color: var(--secondaryGreen); 
}

.longContainer {
    background-color: var(--positive);   
}



.textMargins {
    margin: 15px 0px;
}

.white {
    color:var(--backgroundLightGray);
}

.blue {
    color:var(--waybozBlue);
}

.paragraphContainer {
    height:85px;
    line-height: 1.25;
}

.someButtMargin {
    margin-top: 25px;
}

.trialImg {
    overflow: hidden;
    width: 100%;
}