.emptyContainer {
    height:100%;
    width:100%;
}

.emptyContainer p, .emptyContainer img, .button {
padding: 25px;
}

.emptyContainer img {
    margin-top: 50px;
}

.emptyContainer .button {
    margin-bottom: 100px;
}

@media only screen and (max-width: 799px) {
    .emptyContainer img {
        width:fill;
        max-width: 400px;
    }
    
}