.buttonFlex {
    display:flex;
    justify-content: space-between;
    margin-top: 40px;
}

.drawerButton, .buttonSelected {
    border: solid 1px var(--positive);
    border-radius: 10px;
    padding: 10px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.drawerButtonWidth {
    width: 27.5%;
    height: 100px;
}

.drawerButton2Width {
    width: 42.5%;
    height: 80px;
}

.buttonSelected {
    background-color: var(--positive);
}

.drawerButton img, .buttonSelected img {
    height: 50px;
    width: auto;
}

.drawerButton:hover, .buttonSelected:hover {
    cursor: pointer;
}

.placeholderParagraphMargin {
    margin: 40px 0px 20px 0px;
}

.plaidMarginTop {
    margin-top: 40px;
}

.skip {
    display: flex;
    justify-content: center;
    margin: 10px 0px 40px 0px;  
}

.skip p {
    border-bottom: 1px solid var(--waybozGray);
}

@media only screen and (max-width: 650px) {
    
    .drawerButton, .buttonSelected {
        flex-direction: row;
        height:fit-content;
        justify-content: flex-start;
        align-items: center;
        margin-top: 5px;
    }
    .buttonFlex{
        flex-direction: column;
    }
    .drawerButton img, .buttonSelected img {
        height: 30px;
        width: auto;
        margin-right: 5px;
    }
   .drawerButtonWidth, .drawerButton2Width  {
        width: 90%;
   }
}