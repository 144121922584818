

.App {
  text-align: center;
  display: grid;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mobileHeader {
height:60px;
background-color: var(--waybozBlue);
}



.mobileNav {
  width:100%;
  background-color: white;
  box-shadow: 0px -.5px 1px var(--lineGray);
  z-index: 5;

}

.scrollControlMobile {
  overflow: auto;
  padding-bottom: 30px;
}

.mobileContainer {
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  height:calc(100vh - 60px);
  background-color: var(--backgroundLightGray);
  }

  .mobileFooter {
      position: fixed;
      bottom: 0;
  }

  .shrink {
    max-width: 90%;
  }

.newTransAlert {
  width:90vw;
  display:flex;
  align-items: center;
  min-height:50px;
  background-color:var(--positive);
  color:white;
  position: fixed;
  z-index: 1000;
  bottom: 15px;
  left: 5%;
  transform: translate(0, 0);
  box-shadow: 1px 0px 7px var(--positive); 
  transition: transform 1.0s ease-out;
  border-radius: 15px;
}

.plaidErrAlert {
  width:90vw;
  display:flex;
  align-items: center;
  min-height:50px;
  background-color:var(--negative);
  color:white;
  position: fixed;
  z-index: 1000;
  bottom: 15px;
  left: 5%;
  transform: translate(0, 0);
  box-shadow: 1px 0px 7px var(--negative); 
  transition: transform 1.0s ease-out;
  border-radius: 15px;
}

.newTransAlertHidden {
  transform: translateX(110%);
}

.alertPadding {
  padding-left: 20px;
  padding-right: 20px;

}

.header {
  display:flex;
  background-color: var(--waybozBlue);
  width: calc(100vw - 66px);
  height: 84px;
  margin-left: 66px;
  /*position: fixed;*/
  z-index: 5;
}

.body {
  background-color: var(--backgroundLightGray);
  width: calc(100vw - 66px);
  height: 100%;
  min-height: calc(100vh - 84px);
  margin-left: 66px;
  /*margin-top: 84px;*/
}