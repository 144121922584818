.outer {
    border-bottom: 1px solid var(--lineGray);
}

.transactionRow {
    display: flex;
    text-align: left;
    padding: 10px;
    min-height: 25px;
    justify-content: space-between;
    align-items: center;
}

.unassignedRow {
    background-color: var(--lightRed);
}

.hidden {
    opacity: 15%;
}


.transDate {
    width:10%;
    text-align: left;
    min-width: 110px
}

.transMerchant {
    width:18%;
    text-align: left;
}

.transAmount {
    width:9%;
    display:flex;
    align-items: center;
    justify-content: end;
}

.amountDiv {
    text-align: right;
    width: 25px;
}

.transType {
    display: flex;
    align-items: center;
    width:15%;
    text-align: left;

}

.aiImage {
    width:17.5px;
    padding-right: 5px;
}

.aiImage:hover {
    cursor: pointer;
}

.transProperty {
    width:20%;
    text-align: left;

}

.transAccount {
    width:10%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.transHide {
    width:35px;
    text-align: center;
}

.transHide:hover{
    cursor:pointer;
}

.importantNeg{
    color: var(--negative) !important;
}