.tipContainer {
    padding: 30px;
    border: 1px solid var(--lineGray);
    border-radius: 20px;
    box-shadow: 5px 5px 15px var(--lineGray);
    background-color: var(--secondaryGreen);
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 250px;
    margin: 0% 15%;
}

.tipText {
    text-align: left;
    padding-left: 15px;
}