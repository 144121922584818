.narrowHover:hover {
    cursor: pointer;
}

.transactionRowMobile {
    display: flex;
    text-align: left;
    padding: 10px;
    min-height: 25px;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 20px);
}

.transMobileRightCol {
    display:flex;
    flex-direction: column;
    align-items: end;
    min-width: fit-content;
    width:35%
}

.transMobileLeftCol {
    display:flex;
    flex-direction: column;
    align-items: start;
    max-width: 65%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rowPadding {
padding: 15px 10px;
}

.itemMargin {
margin-top: 10px;
} 

.vendor {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.category {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.aiFlexMobile {
    display:flex;
}

.aiImageMobile {
        width:17.5px;
        padding-right: 5px; 
        margin-top: 7px;
}

.aiImageMobile:hover {
    cursor: pointer;
}