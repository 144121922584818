.includeInAll {
    display:flex;
    justify-content: end;
    align-items: center;
    margin-top: -30px;
    margin-bottom: 30px;
}






.primaryTitle {
padding: 15px;
text-align: center;

}

.secondaryTitle {
padding-top: 20px;
}

.groupContainer {
    margin:auto;
    padding-bottom: 25px;
}

.propertyAddress {
    margin: 10px;
}

.imageContainer {
    border-radius: 10px 10px 10px 10px;
    background-color: var(--secondaryGreen);
}

.propertyImage {
    margin: auto;
    width: 100%;
    border-radius: 10px 10px 0px 0px;

}

.comingSoon {
    color: var(--positive);
    padding: 20px;
    text-align: center;
}



.propValues {
    display:flex;
    justify-content: flex-end;
    align-items: center;
}

.propValues img {
    margin: 0;
}



.values {
    display:flex;
    justify-content: flex-end;
}

.dates {
    width: 110px;
    display:flex;
    justify-content: flex-end;
    padding: 6px 0px;

}

.trashcan {
    width: 35px;
    display:flex;
    justify-content: flex-end;
}

.twotitles {
display:flex;
justify-content: space-between;
}

.mortgageDivider {
    border-top: 1px dashed var(--lineGray);
    margin-bottom: 25px;
}

.pmiStuff {
    margin-top: 10px;
}



.delete {
    width: 100%;
    display: flex;
    justify-content: center;
}

.hover:hover{
    cursor: pointer;
}

.gimmieSomeTopMargin {
    margin-top: 30px;
}

