.plaid-link-modal {
    font-family: 'inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: bold !important;
    font-size: 1rem !important;
    background-color: var(--positive) !important;
    padding: 12px 24px !important;
    border-radius: 25px !important;
    color: white;
    transition: all 150ms ease;
    cursor: pointer;
    border: none !important;
    width: 300px;
}

.plaid-link-modal-update {
    font-family: 'inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: bold !important;
    font-size: 1rem !important;
    background-color: white !important;
    padding: 12px 24px !important;
    border-radius: 25px !important;
    color: var(--positive);
    transition: all 150ms ease;
    cursor: pointer;
    border: 1px solid var(--positive) !important;
    width: 200px;
}

.plaid-reconnect-modal {
    text-decoration: underline;
    cursor: pointer;
    background: none !important;
    border: none !important;
    display: inline-block;
    font-size: 21px;
    font-weight: bold;
    padding: 0 !important;
    color: white;
    padding-left: 5px !important;
}