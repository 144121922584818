

    
   
   
    
    .primaryTitle {
    padding: 15px;
    text-align: center;
    
    }
    
    .secondaryTitle {
    padding-top: 20px;
    }
    
    .groupContainer {
        margin:auto;
        padding-bottom: 25px;
    }
    
    .propertyAddress {
        margin: 10px;
    }
    
    .MultiOnRow {
        display: flex;
        justify-content: space-between;
    }

    .MultiOnRowAlways {
        display: flex;
        justify-content: space-between;
    }
    
    
    .boxWidth1 {
        width:60%;
    }
    
    .boxWidth2 {
        width:15%;
    }
    
    .boxWidth3 {
        width:47.5%;
    }
    
    .imageContainer {
        border-radius: 10px 10px 10px 10px;
        background-color: var(--secondaryGreen);
    }
    
    .propertyImage {
        margin: auto;
        width: 100%;
        border-radius: 10px 10px 0px 0px;
    
    }
    
    .comingSoon {
        color: var(--positive);
        padding: 20px;
        text-align: center;
    }
    
    
    
    .propValues {
        display:flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    .propValues img {
        margin: 0;
    }
    
    
    
    .values {
        display:flex;
        justify-content: flex-end;
    }
    
    .dates {
        width: 110px;
        display:flex;
        justify-content: flex-end;
        padding: 6px 0px;
    
    }
    
    .trashcan {
        width: 35px;
        display:flex;
        justify-content: flex-end;
    }
    
    .twotitles {
    display:flex;
    justify-content: space-between;
    }
    
    .mortgageDivider {
        border-top: 1px dashed var(--lineGray);
    }
    
    .pmiStuff {
        margin-top: 10px;
    }
    
    
    
    .delete {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .pmiStuffFlex {
        margin-left: auto; 
        margin-right: 0;
        width: fit-content;
    }

    .pmiStuffFlex2 {
        display: flex;
        flex-direction: column;
        width:100%;
    }

    .pmiStuffRow {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .pmiLabelMarg {
        margin-left: 10px;
    }

    .trashMargin {
        margin-left: 5px;
    }

    .placeholderIncExpMargin {
        margin: 5px 0px;
    }

    @media only screen and (max-width: 499px) {
        .MultiOnRow {
            display:block
        }
        .boxWidth1, .boxWidth2, .boxWidth3 {
            width:100%;
        } 
    }