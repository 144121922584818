.scroll {
    max-width: 100%;
    overflow: auto;
}

.minWidth {
    min-width: fit-content;
}

.summaryContainer {
    padding: 15px 15px 0px 15px;
}

.flexAndMargin {
    display:flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.flexAndMargin2 {
    display:flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.flexAndMarginRight{
    display:flex;
    margin-bottom: 10px;  
}

.flexAndMarginRight div {
    text-align: right;
    margin-bottom: 5px;
}


#alignLeft {
    text-align: left;;
}

.moreMarginStats {
    margin-bottom: 25px;
}

.bgSummaryColor {
    background-color: white;
}

/* ------------- Tabs sections ------------------- */


.tab {
    margin: 0px 0px 0px 40px;
}

.tabBorderCashFlow {
    border-bottom: 2px solid var(--cashFlow);
}
.tabBorderAppreciation {
    border-bottom: 2px solid var(--appreciation);
}
.tabBorderPrinciple {
    border-bottom: 2px solid var(--principle);
}
.tabBorderTVG {
    border-bottom: 2px solid var(--positive);
}

/* ------------- Time Period Labels Section ------------------- */


.colWidth {
    width:20%;
    min-width: 170px;
}



.colWidth3 {
    width: 12%;
    min-width: 95px;
}

.colWidth4 {
    width: 8%;
    min-width: 75px;
}

.statsLine {
    border: 1px solid var(--lineGray);
    margin-bottom: 10px;  
    margin-top: -5px;

}


.bold {
    font-weight: 700;
}


/* ------------- Mobile Stuff ------------------- */
.flexAndMarginMobile {
    display:flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.setWidth {
    width: 200px;
}

.carrots:hover {
    cursor: pointer;
}

.summaryRowMobile {
    display:flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.summaryMetricsMobile {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.mobileMetricMargin {
    margin-bottom:10px;
}
