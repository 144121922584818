

.sideNavDrawer { 
    height: 100vh;
    background: white;
    position: fixed;
    width: calc(235px);
    z-index: 200;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
    left: -169px;
    transform: translateX(0);
    transition: transform 0.3s ease-out;
 }

 .sideNavDrawerOpen {
    transform: translateX(calc(100% - 66px));
 }

 .flexy {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;

 }

.flexy:hover, .home:hover {
    cursor: pointer;
}


 .logoContainer {
    display:flex;
    width: 66px;
    justify-content: space-around; 
}



 .textAlign {
    width:100%;
    display: flex;
    justify-content: center;
 }

 .bgColor {
    background-color: var(--secondaryGreen);
 }

 .home {
    background-color: var(--positive);
    height: 84px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.logoDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:10px;
}

.logoDiv img {
    width:100%;
}
