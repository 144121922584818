.calcMargin{
    margin: 20px 0px;
}




.inputResults {
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.inputResultsCol {
    overflow: auto;
    min-width: 40%;
    text-align: left;
    padding: 30px;
    flex-basis: 650px;
    flex-grow: 1;
}





.dataContainer {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    }


.centerMe {
    width:100%;
    text-align: center;
    margin: 10px 0px 30px 0px;
}

.centerMe img {
    margin-bottom: 20px;
}

.alignStuff {
    align-items: center;
}

.growthRate {
    width:25%;
}

.growthRateMobile {
    width:100%;
}

.pDiv {
    width: 70%;
}

.pTweaks {
    line-height: 1.5;
}

.mortDetails {
    width: 21.25%;
}

.project {
    width: 30%;
}






.chartMarg {
    margin: 40px 0px 10px 0px;
}

.resultsRow {
    display:flex;
    align-items: center;
    margin-top: 20px;
}

.number {
    width: 40%;
}

.rightAlign {
    float: right;
}

.text {
    margin-left: 5%;
    width:55%;
}




.tooltip2 {
    display:flex;
    align-items: end;
 }

 .tooltip2 p {
    margin-right: 5px;
 }

 .tooltip2 img:hover {
    cursor: pointer;
 }

 @media only screen and (max-width: 499px) {
    .mortDetails, .project {
        width:100%;
    }
    .inputResultsCol {
        padding: 30px 5%;
    }
    /*.resultsRow {
        flex-wrap: wrap;
    }
    .number {
        flex-basis: 200px;
        flex-grow: 1;

    }
    .text {
        flex-grow: 1;

    }*/
}