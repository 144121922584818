.addMenuContainer {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    max-height:95vh;
    }

.removeTransWidth {
   min-width: 300px;
   max-width: 465px;
}

.removeButton {
    width: 180px;
    padding: 10px;
    color: var(--negative);
    text-decoration: underline;
    text-align: center;
}

.removeButton:hover {
    cursor: pointer;
}

.dateWidth {
    min-width: 150px;
    width: 200px;
}

.accountContainer {
display:flex;
flex-wrap: wrap;
}

.slightLeftMargin {
    margin-left: 5px;
}

.slightRightMargin {
    margin-right: 5px;
}

.containerDisplay {
    align-items: center;
    justify-content: space-between;
}

.backgroundAccout {
    background-color: var(--backgroundLightGray);
    padding: 15px 15px;
    border-radius: 10px;
}

.removeDisplay {
    align-items: center;
    justify-content: space-between;
}

.maxWidth {
    flex-basis: 300px;
    flex-grow: 1;
}

.center {
    display:flex;
    flex-direction: column;
    align-items: center;
}

.accountGroup {
    margin: 0px 0px 0px 0px;
}

.margTop {
    margin: 40px 0px;
}

.margBottom {
    margin: 40px 0px;
}


.imgFit {
    width:20%;
    height:20%;
}

.numNameContainer {
display:flex;
}

.buttonMargin {
    margin-left: 10px;
}

.propTab {
    display: flex;
    flex-wrap: wrap;
}

.individualTab {
margin-top: 20px;
}

.removeAccount {
    display: flex;
    justify-content: center;
    color: var(--negative);
    text-decoration: underline;
}

.removeAccount:hover {
    cursor: pointer;
}

.labelMargin {
    margin-bottom: 10px;
}

.dateRowMargin {
    margin-top: 10px;
}

/*
#edit-account-container .plaid-link-modal{
    background-color: white !important;
    color: red;
    text-decoration: underline;
}

#edit-account-container .plaid-button-container > div {
    text-align: center;
}*/

@media only screen and (max-width: 650px) {
    
    .accountGroup {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 15px 15px 15px;
        width: 100%;
        background-color: var(--backgroundLightGray);
        border-radius: 10px;
        margin-bottom: 10px;
    }
    .backgroundAccout {
        background-color: transparent;
        padding: 15px 15px;
    }

    
}