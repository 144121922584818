.custom-tooltip {
    background-color: white;
    border: 1px solid var(--lineGray);
    padding: 15px;
    width: 200px;
    border-radius: 5px;
}


.custom-tooltip-wide {
    background-color: white;
    border: 1px solid var(--lineGray);
    padding: 15px;
    width: 250px;
    border-radius: 5px;
}


.label {
    margin-bottom: 13px;
}

.toolTipAvgMargin {
    margin-top: 26px;
}



.flexDisplay {
    display: flex;
    justify-content: space-between;
}

.borderTop {
    border-top: 1px solid var(--lineGray);
}

.row {
    margin: 7px 0px;
}

.avgMarBottom {
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: right;
}

.sumPadTop {
    padding-top: 15px;
}

.avgDisclaimer {
    text-align: right;
    padding-top: 7px;
}

.avgMonthMarBottom {
    margin-bottom: 7.5px;

}

.value {
    text-align: right;
    font-weight: 400;
}

.hoverPointer:hover {
    cursor: pointer;
}

.mathBorder {
    border-top: solid 1px var(--lineGray);
    padding-top: 5px;
}

.equityLegend {
    border-top: solid 1px var(--lineGray);
    padding-top: 15px;
}

.updateValue {
    padding-top: 5px;
}

