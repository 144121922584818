.topMargin {
    margin-top: 30px;
}

.image {
    cursor: pointer;
}

.flexed {
    display:flex;
    align-items: center;
}

.topFlexed {
    display:flex;
    align-items: center;
    justify-content: space-between;
}

.remainder {
    justify-content: end;
}

.spaceBelow {
    margin-bottom: 30px;
}

.rightMarg {
    margin-right: 5px;
}

.col1Width {
   width: 75% ;
}

.col2Width {
    width:20%;
}

.multiLineEllipsis {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}


@media only screen and (max-width: 499px) {
    .col1Width, .col2Width {
        width:100%;
    } 
    .topFlexed {
        display: block;
    }
}
