.downloadStyle {
    height: 137px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: space;
    margin: 0px 25px;
    max-width: 200px;
    line-height: 1.5;
}

hr {
    border-top: 1px dashed var(--lineGray);

}

.link {
    text-decoration: underline;
}

.link:hover{
    cursor: pointer;
}

