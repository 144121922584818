.parent {
    display:flex;
    width:100%;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}



.selectClass {
    width: 100px;
}

.someFlex {
    display:flex;
    width:100%;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
}

.child {
    margin-right: 75px;
    text-align: center;
}

@media only screen and (max-width: 599px) {
    .yearSelector {
        margin-left: 15px;
    }
    .child {
        margin-right: 15px;
    }
}