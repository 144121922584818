
.tooltipInfo {
    width: 100%;
    text-align: center;
    overflow: auto;
    padding-bottom: 30px;
    height:95vh;
}

.content {
    text-align: left;
}

ol {
    margin-left: 20px
}

.content p, li {
    line-height: 1.5;
}

.tooltipLI {
    margin-left: 20px;
}

.horLineSolid {
    border: 1px solid var(--lineGray);
    margin: 15px 0px;
}

.paragraphMargin {
    margin-bottom: 15px;
}

.tooltipImage {
    width:100%;
    height:auto;
    padding-bottom: 30px;
}