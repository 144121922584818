.instMenu {
    margin-bottom: 20px;
}

.moreMargin {
    margin-bottom: 40px;
}

.acctLeftMarg {
    padding-left: 40px;
}

.editImg {
    width:36px;
    height:36px;
    margin-bottom: 20px;
}

.editAcct{
    width:20px;
    height:20px;
    color: black;
    position: absolute; 
    top: 5px;
    right: 5px;
}

.instBox {
    order: 1px solid var(--lineGray);
    min-width: 200px;
    height: 65px;
    border-radius: 10px;
    margin-right: 20px;
    overflow:hidden;
    box-shadow: 5px 5px 15px var(--lineGray);
    display:flex;
    flex-direction: column;
    justify-content: center;
    position:relative;
}

.addAccount {
    height: 65px;
    overflow:hidden;
    display:flex;
    flex-direction: column;
    justify-content: center;
    min-width: 200px;
}

.addAccount p {
    text-decoration: underline;
}

.instBox :hover, .addAccount :hover {
    cursor: pointer;
}

.instFlex {
    display:flex;
    justify-content: center;
}

.backgroundSelected {
    background-color: var(--waybozBlue);
}

.backgroundUnselected {
    background-color: white;
}