.backdrop {
    position: fixed;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
    top: 0;
    right: 0;
  }

  .rightDrawer {
    width: 100%;
  }

  .navDrawer {
    width: calc(100% - 66px);
  }